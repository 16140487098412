import React, { useContext } from 'react'
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import RemoveMarkdown from 'remove-markdown'
import moment from 'moment'
import 'moment/locale/fr-ca'
import { RequestInputFiles, RequestType, SelectList } from '@utils/request'
import {
	Asset,
	RequestTheme,
	Dictionary,
	PageDataType,
	Request,
	Collection,
	AssetNode,
	strapiInformationRes,
	informationMessageDic,
	RequestForm
} from 'typings/shared'
import {
	demandes,
	inscriptions,
	permitIcon,
	programsIcon,
	reclamationsLightBlue,
	reportsIcon
} from '@images/icons'
import config from '@utils/config'
import { IRequestConfigsState } from '@services/store/request/type'
import Link, { navigate } from '@components/ui/link'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { authRedirectPage, loginRequest } from '@utils/authConfig'
import { HiddenEnum, LanguageEnum } from '@services/constants'
import { AppStateContext } from '@components/layouts/DynamicLayout'

export const isBrowser = () => typeof window !== 'undefined'

export const assetsToDict = (assets: Asset[]): Dictionary => {
	const res: Dictionary = {}

	for (const asset of assets) {
		res[asset.key] = asset.childStrapiPtcAssetValueTextnode.value
	}

	return res
}

export const assetsWithLocalesToDict = (assets: AssetNode[]): Dictionary => {
	const res = {}

	for (const assetNode of assets) {
		const asset = assetNode.node
		if (asset.locale) {
			if (!res[asset.locale]) {
				res[asset.locale] = {}
			}

			res[asset.locale][asset.key] =
				asset.childStrapiPtcAssetValueTextnode.value
		}
	}

	return res
}

const HIDDEN_DIC = {
	[HiddenEnum.NON]: true,
	[HiddenEnum.OUI]: false
}

export const transformInformationStrapiData = (
	info: strapiInformationRes
): informationMessageDic => {
	const lib = {} as informationMessageDic

	const node: any = info.data
	const {
		attributes: {
			locale,
			message: {
				data: { attributes }
			},
			...res
		}
	} = node

	if (!lib['hide']) {
		lib['hide'] = HIDDEN_DIC[res.cacher]
	}

	if (!lib['type']) {
		lib['type'] = attributes.type
	}

	if (!lib[locale]) {
		lib[locale] = {
			locale,
			...res,
			message: {
				...attributes
			}
		}
	}

	return lib
}

export const transformStrapiData = (
	data: any
): { [key: string]: PageDataType } => {
	const lib = {}
	const assets = assetsWithLocalesToDict(data?.allStrapiPtcAsset?.edges || [])

	Object.keys(assets || [])?.forEach((key) => {
		lib[key] = {
			assets: assets[key]
		}
	})

	for (const page of data?.allStrapiPtcPage?.edges || []) {
		const {
			locale,
			name,
			title,
			description,
			uri,
			ptc_assets: assets
		} = page?.node

		const pages = {
			name,
			title,
			description,
			uri
		}

		if (!lib[locale]) {
			lib[locale] = {}
		}

		if (!lib[locale].pages) {
			lib[locale].pages = {}
		}

		if (!lib[locale][name]) {
			lib[locale][name] = {}
		}

		lib[locale].pages[name] = pages

		lib[locale][name] = {
			...pages,
			assets: assetsToDict(assets)
		}
	}

	for (const requestNode of data?.allStrapiPtcRequest?.edges || []) {
		const {
			name,
			locale,
			title,
			childStrapiPtcRequestDescriptionTextnode: strapiDescription,
			request_id: requestId,
			template_type: templateType,
			ptc_collections: tempCollections,
			ptc_tags: tempTags
		} = requestNode.node

		// create a string with the tags to ease searching
		const tags: string = tempTags?.map(tag => tag?.value)?.join(', ')

		const request: Omit<Request, 'subThemes'> = {
			name,
			title,
			description: strapiDescription?.description,
			requestId,
			templateType,
			tags,
		}

		let ptc_collections = tempCollections.map((cols) => ({
			...cols,
			description:
				cols.childStrapiPtcCollectionDescriptionTextnode?.description || ''
		}))

		const themes: Collection[] = retreiveCollectionByGroup(
			RequestType.theme,
			ptc_collections
		)

		const subThemes: Collection[] = retreiveCollectionByGroup(
			RequestType.subTheme,
			ptc_collections
		)?.filter(subTheme => subTheme != undefined)

		if (!lib[locale]) {
			lib[locale] = {}
		}

		if (!lib[locale].collections) {
			lib[locale].collections = []
		}

		if (!lib[locale].requests) {
			lib[locale].requests = []
		}

		if (themes.length > 0) {
			themes?.forEach((theme) => {
				if (!lib[locale].collections[theme.group]) {
					lib[locale].collections[theme.group] = []
				}

				let themeIndex = lib[locale].collections[theme.group].findIndex(
					(foundTheme) => foundTheme.id === theme.id
				)

				if (themeIndex < 0) {
					themeIndex =
						lib[locale].collections[theme.group].push({
							...theme,
							requestSubThemes: []
						}) - 1
				}

				if (subThemes.length > 0) {
					subThemes?.forEach((subTheme) => {
						let subThemeIndex = lib[locale].collections[theme.group][
							themeIndex
						].requestSubThemes.findIndex(
							(foundSubTheme) => foundSubTheme.name === subTheme.name
						)

						if (subThemeIndex < 0) {
							subThemeIndex =
								lib[locale].collections[theme.group][
									themeIndex
								].requestSubThemes.push({ ...subTheme, requests: [] }) - 1
						}

						lib[locale].collections[theme.group][themeIndex].requestSubThemes[
							subThemeIndex
						].requests.push(request)
					})
				}
			})
		}

		lib[locale].requests.push(request)
	}

	for (const formNode of data?.allStrapiPtcForm?.edges || []) {
		const {
			cacher,
			childStrapiPtcFormDescriptionTextnode: strapiDescription,
			locale,
			name,
			order,
			title,
			form,
			request_id: requestId,
			ptc_collections: tempCollections
		} = formNode.node

		const request: Omit<RequestForm, 'subThemes'> = {
			name,
			title,
			description: strapiDescription?.description,
			requestId,
			cacher,
			order,
			form
		}

		if (!lib[locale].formRequests) {
			lib[locale].formRequests = []
		}

		lib[locale].formRequests.push(request)
	}

	return lib
}

export const retreiveCollectionByGroup = (
	group: string,
	collections: Collection[]
): Collection[] => {
	const list: Collection[] = collections.filter(
		(collection) => collection.group === group
	)

	return list.map((collection) => {
		if (!collection.order) {
			return { ...collection, order: 1 }
		}

		return collection
	})
}

export const pathToPageName = (pathname: string) => {
	const pathLength = pathname?.length

	if (pathLength) {
		const lastChar = pathname[pathLength - 1]

		if (pathLength > 1 && lastChar === '/') {
			pathname = pathname.substring(0, pathLength - 1)
		}
	}

	if (pathname?.includes('/auth/requests/create/')) {
		pathname = '/auth/requests/create/:id'
	} else if (pathname?.match(/\/auth\/requests\/(?!create).*/)) {
		pathname = '/auth/requests/:id'
	} else if (pathname?.match(config.articles.baseUrlMatch)) {
		pathname = '/articles'
	} else if (pathname?.match(config.articles.detailsPathMatch)) {
		pathname = '/articles/:id'
	}

	return (
		{
			'/': 'home',
			'/auth': 'profile-information',
			'/auth/create-request': 'create-request',
			'/auth/create-request/details': 'create-request-details',
			'/auth/requests/create': 'create-request',
			'/auth/requests/create/:id': 'create-request-details',
			'/auth/requests': 'my-requests',
			'/auth/requests/:id': 'my-request-details',
			'/online-services': 'online-services',
			'/faq': 'faq',
			'/auth/profile': 'profile',
			'/articles': 'articles',
			'/articles/:id': 'article-details',
			'/results-requests': 'results-requests'
		}[pathname] || 'home'
	)
}

export const getDataAssets = (data: any): { [key: string]: string } => {
	return assetsToDict(data?.strapiPages?.assets)
}

export const setTo2Digits = (input: number): string => {
	return input < 10 ? '0' + input : '' + input
}

export const formatDateAndTime = (UTCString: string): string => {
	try {
		const date = new Date(UTCString)

		return (
			date.getFullYear() +
			'-' +
			setTo2Digits(date.getMonth() + 1) +
			'-' +
			setTo2Digits(date.getDate()) +
			' ' +
			setTo2Digits(date.getHours()) +
			':' +
			setTo2Digits(date.getMinutes())
		)
	} catch (error) {
		console.error('Error while converting date', JSON.stringify(error))

		return ''
	}
}

export const formatDate = (UTCString: string): string => {
	try {
		return formatDateAndTime(UTCString).split(' ')[0]
	} catch (error) {
		console.error('Error while converting date', JSON.stringify(error))

		return ''
	}
}

export const formatTime = (UTCString: string): string => {
	try {
		return formatDateAndTime(UTCString).split(' ')[1]
	} catch (error) {
		console.error('Error while converting date', JSON.stringify(error))

		return ''
	}
}

export const formatStrapiText = (text?: string): string => {
	if (!text) {
		return ''
	}

	if (isBrowser()) {
		return DOMPurify.sanitize(RemoveMarkdown(text))
	}

	return RemoveMarkdown(text)
}

export const formatHtmlForStrapiText = (text?: string) => {
	if (!text) {
		return ''
	}

	if (isBrowser()) {
		// Custom hook to allow target='_blank' attribute
		DOMPurify.addHook('afterSanitizeAttributes', (node) => {
			if (node.tagName === 'A') {
				node.setAttribute('target', '_blank')
				node.setAttribute('rel', 'noopener noreferrer')
			}
		})

		return DOMPurify.sanitize(marked.parse(text))
	}

	return marked.parse(text)
}

export const sortRequestThemes = (themes: RequestTheme[]): RequestTheme[] => {
	themes?.sort((themeA, themeB) =>
		themeA.order.toString().localeCompare(themeB.order.toString())
	)

	themes?.forEach((theme) => {
		theme?.requestSubThemes.sort((subThemeA, subThemeB) =>
			subThemeA.order.toString().localeCompare(subThemeB.order.toString())
		)

		theme?.requestSubThemes.forEach((subtheme) =>
			subtheme.requests?.sort((requestA, requestB) =>
				requestA.title?.trim().localeCompare(requestB.title.trim())
			)
		)

		theme.requests = []

		theme?.requestSubThemes.forEach((subTheme) => {
			subTheme?.requests.forEach((request) => {
				request.subThemes = []
				const subThemes = theme?.requestSubThemes?.filter((subTheme) =>
					subTheme.requests?.find(
						(req) => req.requestId?.trim() == request?.requestId?.trim()
					)
				)
				subThemes?.forEach((tempSubThemes) =>
					request?.subThemes.push(tempSubThemes?.name)
				)
				theme?.requests.push(request)
			})
		})

		theme?.requests?.sort((requestA, requestB) =>
			requestA.title.trim().localeCompare(requestB.title.trim())
		)

		theme.requests = theme.requests.filter(
			(ele, pos) => theme.requests.indexOf(ele) == pos
		)
	})

	return themes
}

export const capitalize = (text: string) => {
	return text[0].toUpperCase() + text.substring(1)
}

export const timeDisplay = (
	language: string,
	time: string,
	preposition: string
) => {
	moment.locale(language)
	var date = new Date(time)
	var currentDate = new Date()

	if (
		date.getTime() >= currentDate.setDate(date.getDate() - 7) &&
		currentDate.getTime() <= date.setDate(date.getDate() + 7)
	) {
		return moment(time).calendar()
	}

	return moment(time).format(`LL [${preposition}] LT`)
}

export const getIcon = (name: string): string => {
	const icons: { [key: string]: string } = {
		informationsRequests: demandes,
		reports: reportsIcon,
		permetsAuthorizationsAndCertificates: permitIcon,
		programsAndGrants: programsIcon,
		reclamation: reclamationsLightBlue,
		registrationsAndApplications: inscriptions
	}

	if (!icons[name]) {
		return icons.informationsRequests
	}

	return icons[name]
}

export const getParamsFromSearchLocation = (
	search: string
): URLSearchParams => {
	return new Proxy(new URLSearchParams(search), {
		get: (searchParams, prop) => searchParams.get(prop as string)
	})
}

export const toBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})

export const saveInputFilesToLocalStorage = (input: RequestInputFiles) => {
	input.files.forEach((file) => {
		let reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = function () {
			const localInput = localStorage.getItem(input.name)
			const tempObj = !!localInput
				? JSON.parse(`${localStorage.getItem(input.name)}`)
				: { ...input, files: [] }

			if (!tempObj.files.find((tmpFile) => tmpFile.name == file.name)) {
				tempObj.files.push({
					size: file.size,
					name: file.name,
					content: `${reader.result}`,
					type: file.type
				})
				localStorage.setItem(input.name, JSON.stringify(tempObj))
			}
		}
		reader.onerror = function (error) {
			console.error('Error: ', error)
		}
	})
}

export const saveFileToStore = (
	input: RequestInputFiles,
	configs: IRequestConfigsState
): Promise<boolean> => {
	return new Promise<boolean>((resolve, reject) => {
		input.files.forEach((file) => {
			let reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = function () {
				const localInput = configs[input.name]
				let tempObj = localInput || { ...input, files: [] }

				if (!tempObj.files.find((tmpFile) => tmpFile.name == file.name)) {
					tempObj = {
						...tempObj,
						files: [
							...tempObj.files,
							{
								size: file.size,
								name: file.name,
								content: `${reader.result}`,
								type: file.type
							}
						]
					}
				}

				configs[input.name] = tempObj

				// in case, the input contain many files
				if (input.files.size == configs[input.name].files.length) resolve(true)
			}
			reader.onerror = function (error) {
				console.error('Error: ', error)
				reject(error)
			}
		})
	})
}

export const saveSeparetedFileToStore = (
	input: RequestInputFiles,
	configs: IRequestConfigsState
): Promise<boolean> => {
	return new Promise<boolean>((resolve, reject) => {
		let files: any = input.separateFiles
		Object.keys(files).forEach((key) => {
			files[key].forEach((file) => {
				let reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = function () {
					const localInput = { ...configs[input.name] }
					let tempObj = localInput || {}

					if (!tempObj[key]?.find((tmpFile) => tmpFile.name == file.name)) {
						tempObj[key] = {
							size: file.size,
							name: file.name,
							content: `${reader.result}`,
							type: file.type
						}
					}

					configs[input.name] = tempObj

					// in case, the input contain many files
					if (
						Object.keys(files).length == Object.keys(configs[input.name]).length
					)
						resolve(true)
				}
				reader.onerror = function (error) {
					console.error('Error: ', error)
					reject(error)
				}
			})
		})
	})
}

export const toFile = async (
	fileName: string,
	mimeType: string,
	dataURL: string
): Promise<File> => {
	const blob = await (await fetch(dataURL)).blob()
	const file = new File([blob], fileName, {
		type: mimeType,
		lastModified: new Date().getTime()
	})

	return file
}

export const getFilesFromStore = async <T = Set<File>>(
	name: string,
	configs: IRequestConfigsState
): Promise<T> => {
	const input = configs[name]
	if (!input) {
		return new Set<File>([]) as T
	}

	const files: any[] = []

	if (input.files) {
		for (let file of input.files) {
			files.push(await toFile(file.name, file.type, file.content))
		}
	}

	return new Set<File>(files) as T
}

export const getSeparetedFilesFromStore = async (
	name: string,
	configs: IRequestConfigsState
) => {
	const input = configs[name]
	if (!input) {
		return {}
	}

	const files: any = {}

	Object.keys(input).forEach(async (key) => {
		files[key] = new Set<File>([
			await toFile(input[key].name, input[key].type, input[key].content)
		])
	})

	return files
}

export const getFilesFromLocalStorage = async (name: string) => {
	const tempInput = localStorage.getItem(name)
	if (!tempInput) {
		return new Set<File>()
	}

	const input = JSON.parse(tempInput)
	const files: any[] = []

	for (let file of input.files) {
		files.push(await toFile(file.name, file.type, file.content))
	}

	return new Set<File>(files)
}

export const onNavigateToOtherSubjectRequest = () => (e) => {
	const { instance } = useMsal()
	const { language } = useContext(AppStateContext)
	const isUserConnected: boolean = useIsAuthenticated()

	e.preventDefault()

	const link: string = `${config.request.create.baseURL}/other-subject`

	if (!isUserConnected) {
		const authRedirect: string = config.localStorage.authRedirect
		// this will send the right language to display on Azure AD B2C
		const extraQueryParameters = {
			ui_locales: `${language || LanguageEnum.FR}`
		}

		localStorage.setItem(authRedirect, link)

		instance.loginRedirect({
			...loginRequest,
			redirectUri: authRedirectPage,
			extraQueryParameters
		})

		return
	}

	navigate(link)
}

export const getLabelFromList = (list: SelectList[], value) => {
	return list?.find((element) => element.value == value?.toString())?.label
}

export const isAddressValid = (address1: string, address2: string): boolean => {
	return address1 == address2
}

export const isObjectEmpty = (obj: Object): boolean => {
	return Object.keys(obj).length !== 0
}

export const convertStringToNumber = (str: string): number => {
    // Check if the string contains a decimal point
    if (str?.includes('.')) {
        // For decimal numbers, split into integer and fraction parts
        const parts = str.split('.');
        const integerPart = parts[0];
        const fractionPart = parts[1];

        // Combine integer part with parsed fraction part
        let result = parseInt(integerPart, 10);

        if (fractionPart) {
            // Handle fractional part
            const fractionValue = parseInt(fractionPart, 10) / Math.pow(10, fractionPart.length);
            result += fractionValue;
        }

        return result;
    } else {
        // For integer numbers, parse directly
        return parseInt(str, 10);
    }
}