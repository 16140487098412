import React, { FC, useContext, useEffect, useState } from 'react'
import {
	facebookIcon,
	instagramIcon,
	linkedinIcon,
	logoLavalFooterIcon,
	twitterNewIcon,
	youtubeIcon
} from '@images/icons'
import { Icon } from '@components/ui/Icon'
import { joinClasses } from '@utils/styles'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import Link from '@components/ui/link'
import { LanguageEnum } from '@services/constants'

import Text from '@components/ui/text'
import config from '@utils/config'
import Browser from '@components/browser'
import {
	classes,
	newsletterLink,
	whoCanSeeThisPageLink
} from './__footer.utils'

const Footer: FC = () => {
	const { isMobile } = Browser()
	const { pageData, language } = useContext(AppStateContext)
	const currentYear: number = new Date().getFullYear()

	const [facebookLink, setFacebookLink] = useState<string>(
		`${process.env.FACEBOOK_URL}`
	)
	const [instagramLink, setInstagramLink] = useState<string>(
		`${process.env.INSTAGRAM_URL}`
	)

	useEffect(() => {
		if (isMobile) {
			setFacebookLink(`${process.env.FACEBOOK_APP_URL}`)
			setInstagramLink(`${process.env.INSTAGRAM_APP_URL}`)
		}
	}, [])

	return (
		<footer className={classes.root}>
			<div className={classes.footerRow}>
				{/* social footer */}
				<div className={classes.footerSocial}>
					<div className={classes.footerTitle}>
						{formatStrapiText(pageData?.assets?.footer_followUs)}
					</div>
					<ul className={classes.socials}>
						<li>
							<Link to={facebookLink} target="_blank">
								<Icon src={facebookIcon} />
							</Link>
						</li>
						<li>
							<Link to={`${process.env.TWITTER_URL}`} target="_blank">
								<Icon src={twitterNewIcon} />
							</Link>
						</li>
						<li>
							<Link to={`${process.env.YOUTUBE_URL}`} target="_blank">
								<Icon src={youtubeIcon} />
							</Link>
						</li>
						<li>
							<Link to={instagramLink} target="_blank">
								<Icon src={instagramIcon} />
							</Link>
						</li>
						<li>
							<Link to={`${process.env.LINKEDIN_URL}`} target="_blank">
								<Icon src={linkedinIcon} />
							</Link>
						</li>
					</ul>
				</div>
				{/* newsletter footer */}
				<div className={classes.footerNews}>
					<div className={classes.footerTitle}>
						{formatStrapiText(pageData?.assets?.footer_newsletter)}
					</div>
					<div className={classes.newsContent}>
						<div>
							{formatStrapiText(
								pageData?.assets?.footer_newsletter_description
							)}
						</div>
						<a
							href={
								language === LanguageEnum.EN
									? `${process.env.NEWS_LETTER_SUBSCRIPTION}`
									: `${process.env.INFOLETTRE_SUBSCRIPTION}`
							}
							className={classes.button}
							target="_blank"
							rel="noreferrer"
						>
							{formatStrapiText(pageData?.assets?.footer_newsletter_button)}
						</a>
					</div>
				</div>
			</div>
			{/* <hr /> */}
			<div className={classes.footerSectionRow}>
				<Link to={config.homepage.baseURL} className={classes.link}>
					<Icon src={logoLavalFooterIcon} className={classes.logo} />
				</Link>
				<span className={joinClasses([classes.span, classes.copyRight])}>
					<Text
						content={`${pageData?.assets?.footer_copyright} ${currentYear} - ${pageData?.assets?.footer_rightsReserved}`}
					/>
				</span>
				<Link
					className={classes.link}
					to={
						language === LanguageEnum.EN
							? `${process.env.ACCESSIBILITY_URL}`
							: `${process.env.ACCESSIBILITE_URL}`
					}
					target="_blank"
				>
					{formatStrapiText(pageData?.assets?.footer_accessibility)}
				</Link>
				{/* <span className={classes.span}>|</span> */}
				<Link
					className={classes.link}
					to={
						language === LanguageEnum.EN
							? `${process.env.NOTICES_URL}`
							: `${process.env.AVIS_URL}`
					}
					target="_blank"
				>
					{formatStrapiText(pageData?.assets?.footer_notices)}
				</Link>
				{language === LanguageEnum.EN && (
					<>
						{/* <span className={classes.span}>|</span> */}
						<a
							className={classes.link}
							target="_blank"
							href={whoCanSeeThisPageLink}
						>
							{formatStrapiText(pageData?.assets?.who_can_view_this_page)}
						</a>
					</>
				)}
			</div>
		</footer>
	)
}

export default Footer
