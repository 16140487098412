import { ICommentsAttachmentDTO } from '@services/dtos/comments.dto'
import {
	ITimelineActivityDTO,
	ITimelineDTO,
	ITimelineValueChangeDTO
} from '@services/dtos/timeline.dto'
import TimelineRepository from '@services/repositories/timeline.repository'
import {
	ICommentRepository,
	ICommentsAttachment,
	IRequest,
	ITimeline,
	ITimelineActivity,
	ITimelineRepository,
	ITimelineValueChange
} from '@services/types'
import {
	Expose,
	Exclude,
	instanceToPlain,
	plainToInstance,
	Type
} from 'class-transformer'
import { CommentsAttachment } from '@services/models/comments.model'
import { CommentRepository } from '@services/repositories'

export class TimelineActivity implements ITimelineActivity {
	@Expose({ name: 'id' })
	id: string = ''

	@Expose({ name: 'type' })
	type: string = ''

	@Expose({ name: 'overview' })
	overview: string = ''

	@Expose({ name: 'description' })
	description: string = ''

	@Expose({ name: 'attachments' })
	@Type(() => CommentsAttachment)
	attachments?: ICommentsAttachment[]

	@Expose({ name: 'metadata' })
	metadata: string = ''

	@Expose({ name: 'portal_message_read' })
	portalMessageRead: boolean = false

	@Expose({ name: 'category' })
	category?: number

	constructor(dto?: ITimelineActivityDTO) {
		if (dto) {
			this.parse(dto)
		}
		this._repository = new CommentRepository()
	}

	@Exclude()
	private _repository?: ICommentRepository

	@Exclude()
	static serialize = (timelineActivity: ITimelineActivity) => {
		const dto = instanceToPlain(timelineActivity)

		return dto
	}

	@Exclude()
	public parse = (timelineActivity: ITimelineActivityDTO) => {
		Object.assign(this, plainToInstance(TimelineActivity, timelineActivity))
	}

	@Exclude()
	public setActivityAsRead = async (activityType?: string) => {
		const status = await this._repository?.setCommentAsRead(
			this.id,
			activityType
		)
		if (status) {
			this.portalMessageRead = true
		}
	}
}

export class Timeline implements ITimeline {
	@Expose({ name: 'from' })
	from: string = ''

	@Expose({ name: 'date' })
	date: string = ''

	@Expose({ name: 'activity' })
	@Type(() => TimelineActivity)
	activity?: ITimelineActivity

	@Expose({ name: 'value_change' })
	@Type(() => TimelineValueChange)
	valueChange?: ITimelineValueChange

	@Exclude()
	private _repository?: ITimelineRepository

	constructor(dto?: ITimelineDTO) {
		if (dto) {
			this.parse(dto)
		}
		this._repository = new TimelineRepository()
	}

	@Exclude()
	static serialize = (timeline: ITimeline): ITimelineDTO => {
		const dto = instanceToPlain(timeline)

		return dto as ITimelineDTO
	}

	@Exclude()
	public parse = (timeline: ITimelineDTO) => {
		Object.assign(this, plainToInstance(Timeline, timeline))
	}

	@Exclude()
	static buildAsync = async (requestId: IRequest['id']) => {
		const repository = new TimelineRepository()
		const dto = await repository.getTimeline(requestId!)

		return dto
	}

	@Exclude()
	static buildFiles = async (
		attachmentId: ICommentsAttachment['id']
	): Promise<ICommentsAttachmentDTO> => {
		const repository = new TimelineRepository()
		const dto = await repository.getAttachmentsFiles(attachmentId)

		return dto
	}
}

export class TimelineValueChange implements ITimelineValueChange {
	@Expose({ name: 'type' })
	type: string = ''

	@Expose({ name: 'value' })
	value: string = ''

	@Expose({ name: 'value_label' })
	valueLabel: string = ''

	@Exclude()
	public parse = (timelineValueChange: ITimelineValueChangeDTO) => {
		Object.assign(
			this,
			plainToInstance(TimelineValueChange, timelineValueChange)
		)
	}

	@Exclude()
	static serialize = (
		timelineValueChange: TimelineValueChange
	): ITimelineValueChangeDTO => {
		const dto = instanceToPlain(timelineValueChange) as ITimelineValueChangeDTO

		return dto
	}

	constructor(dto: ITimelineValueChangeDTO) {
		if (dto) {
			this.parse(dto)
		}
	}
}
