import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import { ButtonStyle, Colors } from '@utils/css-variables'

export const classes = makeClasses({
	root: {
		position: 'relative' as 'relative',
		bottom: 'auto',
		width: '100%',
		background: Colors.lightBlack,
		color: Colors.white,
		padding: '60px 40px 20px',
		fontSize: '13px',
		lineHeight: '25px',
		fontWeight: 400,
		display: 'flex',
		flexDirection: 'column' as 'column',
		boxSizing: 'border-box'
	},
	footerRow: {
		display: 'flex',
		gap: '100px',
		marginBottom: '30px',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		[Breakpoints.maxWidth(1300)]: {
			gap: '35px',
		},
		[Breakpoints.maxWidth('xl')]: {
			gap: '50px',
		},
		[Breakpoints.maxWidth('laptop')]: {
			flexDirection: 'row',
			gap: '30px',
		}
	},
	footerSectionRow: {
		display: 'flex',
		alignItems: 'flex-end',
		gap: '20px',
		marginBottom: '30px',
		borderTop: `1px solid rgba(255, 255, 255, 0.3)`,
		paddingTop: '20px',
		paddingBottom: '20px',
		flexWrap: 'wrap'
	},
	footerTitle: {
		fontWeight: 'bold',
		fontSize: '18px',
		marginBottom: '14px',
		lineHeight: '1.4'
	},
	footerNews: {
		maxWidth: '670px'
	},
	newsContent: {
		display: 'flex',
		alignItems: 'flex-start',
		gap: '15px',
		[Breakpoints.maxWidth('md')]: {
			flexDirection: 'column',
		},
		'& > div': {
			fontSize: '15px',
			lineHeight: '1.5',
			marginBottom: '5px'
		}
	},
	compactRoot: {
		padding: '10px 30px 10px !important',
		position: 'absolute',
		left: 0,
		bottom: 0
	},
	logo: {
		width: '70px',
		height: '25px'
	},
	link: {
		alignItems: 'center',
		color: Colors.white,
		textDecoration: 'underline',
		'&:hover': {
			color: Colors.lightBlue,
		},
		'&:visited': {
			color: Colors.white
		},
		'&:active': {
			color: Colors.white
		}
	},
	div: {
		textAlign: 'center' as 'center'
	},
	compactDiv: {
		margin: 'auto !important'
	},
	span: {
		display: 'inline-flex',
		alignItems: 'flex-end',
		padding: '0 10px',
		p: {
			margin: 0
		}
	},
	socials: {
		listStyle: 'none',
		paddingLeft: 0,
		margin: 0,
		height: '35px',
		li: {
			display: 'inline-flex',
			paddingLeft: 0,
			margin: '0 5px',
			a: {
				display: 'inline-block',
				height: '35px'
			},
			img: {
				width: '35px',
				height: '35px'
			},
			'&:first-child': {
				marginLeft: 0
			}
		},
		[Breakpoints.maxWidth('md')]: {
			marginTop: '5px'
		}
	},
	copyRight: {},
	button: {
		...ButtonStyle,
		backgroundColor: Colors.secondary,
		color: `${Colors.white} !important`,
		fontSize: '16px',
		padding: '15px 30px',
		border: 'none',
		whiteSpace: 'nowrap',
		'&:hover': {
			background: Colors.darkBlue2
		}
	}
})

export const whoCanSeeThisPageLink: string =
	'https://www.quebec.ca/en/gouvernement/politiques-orientations/langue-francaise/modernization-charter-french-language#c214034'

